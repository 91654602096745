@font-face {
  font-family: "inter";
  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "inter-bold";
  src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "inter-light";
  src: url("./assets/fonts/Inter-Light.ttf") format("truetype");
}

#root_body p {
  /* font-size: 14px; */
  line-height: 24px;
  /* font-weight: 500; */
}

body {
  line-height: 24px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/public/Icons/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
  /* Adjust this value to align the icon properly */
}

.table-search .form-control {
  padding-left: 2.375rem;
}

.table-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 24px;
  height: 24px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  background-image: url('/public/Icons/search.svg');
  background-repeat: no-repeat;
  margin: 8px;
}

table {
  border-collapse: separate;
  border-spacing: 10px;
}

/* td,th {padding: .5em 1em;} */

/* tbody, tr {
  background-color: #fff;
  height: 32px;
  border-radius: 10px;
  color: #667080;
} */

thead,
th {
  font-size: clamp(8px, 2vw, 16px);
  color: #344454;
  vertical-align: middle;
  font-family: "inter-bold", sans-serif !important;
  font-weight: "600" !important;
}


tbody,
td {
  font-size: clamp(8px, 2vw, 14px);
  color: #667080;
  vertical-align: middle;
}

* {
  font-family: "inter", sans-serif !important;
}

.inter {
  font-family: "inter", sans-serif !important;
}

.inter-bold {
  font-family: "inter-bold", sans-serif !important;
  font-weight: "600" !important;
}

.inter-light {
  font-family: "inter-light", sans-serif !important;
}

.sidenav---sidenav---_2tBP {
  background: #ffffff !important;
  display: block;
  height: 100vh;
  position: fixed !important;
}

/* .form-group>input {
  border: 1px solid #ABBCCC !important;
  border-radius: 2px !important;
}

.form-group>textarea {
  border: 1px solid #ABBCCC !important;
  border-radius: 0px !important;
} */

.navbar {
  background: white !important;
  padding: 5px 0px 0px 0px !important;
}

#root_div {
  position: relative;
  background: #f5f5f5;
  box-sizing: border-box;
  overflow: hidden;
}

#root_body {
  padding: 20px 30px 40px 30px;
}

.sidenav---icon-bar---u1f02 {
  background: #8d8d8d !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #000000 !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #8d8d8d !important;
}

/* 
.dropdown-title::after {
  display: none !important;
} */

#navbar-light-example .dropdown-toggle::after {
  display: none !important;
}

#party_master ul button {
  border-radius: 0px !important;
  padding: 18px;
}

#party_master .nav-link {
  color: black !important;
  font-size: clamp(16px, 2vw, 18px);
  font-weight: 600;
}

#party_master .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: white !important;
  background-color: #344454 !important;
}

#party_master .nav-pills {
  border-bottom: 2px solid #f5f5f5;
}



/* button styles */
.btn-pink {
  background: #4E7DAE !important;
  color: white !important;
  border-color: #4E7DAE;
}

.btn-pink:hover {
  background: #45709d !important;
  color: white !important;
  border-color: #45709d;
}

.btn-pink:focus {
  box-shadow: 49, 132, 253;
}

.btn-pink:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #45709d;
  background: #45709d;
}

.btn-pink:disabled {
  color: white !important;
  border-color: #4E7DAE;
  background: #4E7DAE;
}




.btn-light-grey {
  background: #8D8D8D !important;
  color: white !important;
  border-color: #8D8D8D;
}

.btn-light-grey:hover {
  background: #7b7979 !important;
  color: white !important;
  border-color: #7b7979;
}

.btn-light-grey:focus {
  box-shadow: 49, 132, 253;
}

.btn-light-grey:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #7b7979;
  background: #7b7979;
}

.btn-light-grey:disabled {
  color: white !important;
  border-color: #8D8D8D;
  background: #8D8D8D;
}






/* button styles */
.btn-grey {
  background: #344454 !important;
  color: white !important;
  border-color: #344454;
}

.btn-grey:hover {
  background: #2f3e4d !important;
  color: white !important;
  border-color: #2f3e4d;
}

.btn-grey:focus {
  box-shadow: 49, 132, 253;
}

.btn-grey:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #2f3e4d;
  background: #2f3e4d;
}

.btn-grey:disabled {
  color: white !important;
  border-color: #344454;
  background: #344454;
}

.btn-lightblue {
  background: #ABBCCC !important;
  color: #2A3643 !important;
  border-color: #ABBCCC;
}

.btn-lightblue:hover {
  background: #87a4be !important;
  color: #2A3643 !important;
  border-color: #87a4be;
}

.btn-lightblue:focus {
  box-shadow: 49, 132, 253;
}

.btn-lightblue:active {
  color: #2A3643 !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #87a4be;
  background: #87a4be;
}

.btn-lightblue:disabled {
  color: #2A3643 !important;
  border-color: #ABBCCC;
  background: #ABBCCC;
}

.btn-darkblue {
  background: #4E7DAE !important;
  color: white !important;
  border-color: #4E7DAE;
}

.btn-darkblue:hover {
  background: #4778ac !important;
  color: white !important;
  border-color: #4778ac;
}

.btn-darkblue:focus {
  box-shadow: 49, 132, 253;
}

.btn-darkblue:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #4778ac;
  background: #4778ac;
}

.btn-darkblue:disabled {
  color: white !important;
  border-color: #4E7DAE;
  background: #4E7DAE;
}




/* button styles */
.btn-yellow {
  background: #EEAE2D !important;
  color: black !important;
  border-color: #EEAE2D;
  border-radius: 0px;
}

.btn-yellow:hover {
  background: #dfa32c !important;
  color: black !important;
  border-color: #dfa32c;
}

.btn-yellow:focus {
  box-shadow: 49, 132, 253;
}

.btn-yellow:active {
  color: #344454 !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #dfa32c;
  background: #dfa32c;
}

.btn-yellow:disabled {
  color: #344454 !important;
  border-color: #EEAE2D;
  background: #EEAE2D;
}

.btn-yellow-wt {
  background: #EEAE2D !important;
  color: white !important;
  border-color: #EEAE2D;
  border-radius: 0px;
}

.btn-yellow-wt:hover {
  background: #dfa32c !important;
  color: white !important;
  border-color: #dfa32c;
}

.btn-yellow-wt:focus {
  box-shadow: 49, 132, 253;
}

.btn-yellow-wt:active {
  color: #344454 !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #dfa32c;
  background: #dfa32c;
}

.btn-yellow-wt:disabled {
  color: #344454 !important;
  border-color: #EEAE2D;
  background: #EEAE2D;
}








input.control-border {
  border: 1px solid #ABBCCC;
  border-radius: 0px;
  line-height: 24px;
}

textarea.control-border {
  border: 1px solid #ABBCCC;
  border-radius: 0px;
  line-height: 24px;
}

.lbl-style {
  font-size: 15px;
  color: #344454;
}

.pc_card_title {
  font-size: clamp(16px, 20px, 20px);
}

.items-center {
  align-items: center;
}

.m-0 {
  margin: 0;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-9 {
  margin-right: 9px;
}

::-webkit-input-placeholder {
  color: #8D8D8D;
  font-size: 16px;
}

.w-full {
  width: 100%;
}

.calender {
  background: #ffff;
  margin-top: 40px;
  padding: 30px;
}

.calender .week {
  border-bottom: 3px solid #000000;
  margin: 0px 10px;
}

.calender .date {
  border-bottom: 3px solid #000000;
  margin: 10px 10px;
  height: 200px;
}

.editIcon {
  background-color: #2A3643;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.editIcon-sm {
  background-color: #2A3643;
  border-radius: 15%;
  display: inline-block;
  padding: 2px 6px;
  cursor: pointer;
}



.deleteIcon {
  background-color: #FF134E;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.deleteIcon-sm {
  background-color: #FF134E;
  border-radius: 15%;
  display: inline-block;
  padding: 2px 6px;
  cursor: pointer;
}

.downloadIcon {
  background-color: #EEAE2D;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.bg-grey {
  background-color: #2A3643 !important;
  color: white !important;
}

.pdrIcon {
  background-color: #ABBCCC;
  border-radius: 15%;
  display: inline-block;
  padding: 4px 6px;
  cursor: pointer;
}

.spanStyle {
  background: #EEAE2D;
  padding: 1px;
}

.cursor_pointer {
  cursor: pointer;
}

.bg-lightgrey {
  background: rgba(198, 198, 199, 0.205);
}


/* --bs-btn-color: #fff;
--bs-btn-bg: #FF134E;
--bs-btn-border-color: #FF134E;
--bs-btn-hover-color: #fff;
--bs-btn-hover-bg: #e40c42;
--bs-btn-hover-border-color: #e40c42;
--bs-btn-focus-shadow-rgb: 49, 132, 253;
--bs-btn-active-color: #fff;
--bs-btn-active-bg: #e40c42;
--bs-btn-active-border-color: #e40c42;
--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
--bs-btn-disabled-color: #fff;
--bs-btn-disabled-bg: #FF134E;
--bs-btn-disabled-border-color: #FF134E; */

.signin {
  background: #03403a;
  min-height: 100vh;
  position: relative;
}

.signin #title {
  margin-top: 50px;
}

.signin .card {
  border: none;
}

.signin .card .card-header {
  padding: 1.25rem !important;
  color: white;
}

.card_title {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.page-wrapper .page-main-header {
  background-color: #00897b;
}

.page-main-header .main-header-right svg line {
  color: #ffff;
}

.authentication-main .auth-innerright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 62vh;
}

.button-auth {
  margin: 0 -10px;
}

.authentication-main {
  background: #f6f7fb;
  padding: 15px 100px;
}

.authentication-box {
  width: 500px;
  z-index: 2;
  margin: 0 auto;
}

.authentication-box .theme-form {
  padding-top: 20px;
}

.authentication-box .theme-form .form-group .col-form-label {
  color: #999;
}

.authentication-box .theme-form .social .form-row {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.authentication-box h3 {
  color: #2a3142;
  font-weight: 600;
  letter-spacing: 1px;
}

.authentication-box h4 {
  color: #313131;
  font-weight: 600;
  letter-spacing: 1px;
}

.authentication-box h6 {
  color: #777777;
  letter-spacing: 1px;
  font-size: 14px;
}


/* Style for Page Heading */
.page-heading {
  font-size: clamp(16px, 2vw, 30px);
  font-family: "inter-bold", sans-serif !important;
  font-weight: "600" !important;
  line-height: 24px;

  @media (max-width: 992px) {

    font-size: clamp(16px, 2vw, 24px);
  }

  @media (max-width: 576px) {

    font-size: clamp(16px, 2vw, 16px);
  }
}

.label-text {
  font-size: clamp(16px, 2vw, 18px);
  font-weight: 700;
  line-height: 24px;
}

/* Style for Page Heading */

/* Style for Bold Header */
.button-text {
  font-size: clamp(8px, 2vw, 18px);
  font-weight: 500;
  line-height: 24px;

  @media (max-width: 992px) {
    /* Tablet */
    font-size: clamp(8px, 2vw, 12px);
  }

  @media (max-width: 576px) {
    /* Mobile */
    font-size: clamp(8px, 2vw, 8px);
  }
}

/* Style for Bold Header */

/* Style for tabs pills text */
label.txt-control {
  font-size: clamp(8px, 2vw, 16px);
  font-weight: 500;
  line-height: 24px;
}

.txt-control {
  font-size: clamp(8px, 2vw, 16px);
  font-weight: 500;
  line-height: 24px;
}

.entriesPerPage {
  font-size: 16px;
}

/* Style for tabs pills text */

.nc .filter,
input::placeholder,
textarea::placeholder {
  font-size: 14px !important;
}












/* this class is used to add background color to table header */
.table-grey {
  --bs-table-color: #fff;
  --bs-table-bg: #2A3643;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: white;
  /* border-color: var(--bs-table-border-color); */
}

/* this class is used to add background color to table header */
.table-indigo {
  --bs-table-color: #fff;
  --bs-table-bg: #4E7DAE;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: white;
  /* border-color: var(--bs-table-border-color); */
}


/* Common table styles applied for all tables */
table th {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  /* padding-left: 20px !important; */
  /* padding-right: 20px !important; */
  min-width: 100px !important;
  /* font-size: 16px !important; */
}


table thead tr th {
  font-size: 14px;
}

table tr th:first-child {
  padding-left: 16px !important;
}

table tr td:first-child {
  padding-left: 16px !important;
}

table td {
  font-size: 14px !important;
}

.sidenav---sidenav-toggle---1KRjR {
  height: 56px !important;
}


#root_div_main {
  transition: margin-left .5s;
  position: relative;
  background: #f5f5f5;
  box-sizing: border-box;
  overflow: hidden;
}


/* below class is used for styling the filter layout sidebar */
.customsidebar {
  height: 100%;
  width: 0;
  max-width: 320px !important;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ABBCCC;
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
}

.customsidebar .content {
  overflow-y: auto;
  max-height: 90vh;
  min-height: 90vh;
  margin-top: 40px;
  /* padding-top: 40px; */
  /* padding-left: 18px; */
  /* padding-right: 18px; */
  padding: 18px;
}

.customsidebar #basic-typeahead-single {
  transform: unset !important;
  inset: unset !important;
}

.rbt-menu {
  position: absolute !important;
  inset: 0px auto auto 0px !important;
  display: block !important;
  max-height: 300px !important;
  overflow: auto !important;
  transform: translate(0px, 38px) !important;
  width: 288.328px !important;
}



/* close button for sidebar layout */
.customsidebar .closebtn {
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 18px;
  font-size: 36px;
  cursor: pointer;
}

/* .customsidebar .closebtn:hover {
  color: #000000;
} */


.supplier_customsidebar {
  height: 100%;
  width: 0;
  max-width: 320px !important;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ABBCCC;
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
}

.supplier_customsidebar .content {
  overflow-y: auto;
  max-height: 90vh;
  min-height: 90vh;
  margin-top: 60px;
}

.machine_customsidebar {
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  transition: 0.5s ease-in-out;
}

.machine_customsidebar .content {
  overflow-y: auto;
  max-height: 100vh;
  min-height: 100vh;

}

.supplier_customsidebar .closebtn {
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 18px;
  font-size: 36px;
  cursor: pointer;
}

.custom-close-btn {
  text-decoration: none;
  font-size: 24px;
  color: #000000;
  transition: 0.3s;
  cursor: pointer;
}


/* datepicker dropdown doesnt properly display inside sidebar for handling that below styles are required */
#root_div_main .fromDate .react-datepicker-popper {
  position: absolute;
  inset: unset !important;
  transform: unset !important;
  z-index: 9999 !important;
}

#root_div_main .fromDate .react-datepicker__triangle {
  position: absolute !important;
  left: 15% !important;
  transform: translate(0px, 0px) !important;
}

#root_div_main .toDate .react-datepicker-popper {
  position: absolute;
  inset: unset !important;
  transform: unset !important;
  z-index: 9999 !important;
}

#root_div_main .toDate .react-datepicker__triangle {
  position: absolute !important;
  left: 15% !important;
  transform: translate(0px, 0px) !important;
}


.nc_detail_label {
  color: #666666;
  font-size: 14px;
}

.form_label {
  color: #000000;
  font-size: 14px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.err-msg {
  font-size: 14px;
}


.prod_container {
  display: flex;
  justify-content: center;
  /* Center the child elements horizontally */
}

/* Child divs */
.prod_box {
  padding: 6px 10px;
  border: 1px solid #c6c6c6;
  margin: 6px;
  cursor: pointer;
  color: black;
  letter-spacing: 0.5px;
}

.prod_box.active {
  background-color: #344454;
  border: none;
  color: white;
}

.prod_box span {
  font-size: 14px;
}

/* Optional: Apply responsive styles for smaller screens */
@media (max-width: 768px) {
  .prod_container {
    flex-wrap: wrap;
    /* Allow wrapping of child elements on smaller screens */
  }
}

/* Navbar styles */
#main_link_nav .nav-link {
  font-family: "inter-bold", sans-serif !important;
  letter-spacing: 0.5px;
  color: #2a3643;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

/* making selected link active in navbar */
.navbar-nav .nav-link.active {
  /* background: #4e7dae !important; */
  /* border-radius: 12px; */
  color: #ff134e !important;
  border-bottom: 3px solid #ff134e;
}

.master_screen #ccard .card .card-title {
  font-family: "inter-bold", sans-serif !important;
  color: #2a3643;
  font-size: 16px;
  display: inline-block;
  border-bottom: 2px solid #fff;
}

.master_screen #ccard .card:hover .card-title {
  color: #ff134e !important;
  display: inline-block;
  border-bottom: 2px solid #ff134e;
}

.product .dropdown-menu {
  background-color: #dde1e6 !important;
}

.no-decor {
  text-decoration: none;
}


/* reducing border radius of all input type in project */
.form-control-sm {
  border-radius: 2px !important;
}

.form-control {
  border-radius: 2px !important;
}


/* styles used for pagination */
.pagination .active>.page-link {
  background-color: #2a3643 !important;
  border-color: #2a3643 !important;
  color: white !important;
}

.pagination .page-link {
  color: #2a3643 !important;
}


/* modal close button image */
.btn-close {
  --bs-btn-close-bg: url("./assets/images/modalCross1.png") !important;
}


.image_gallery .image-container {
  position: relative;

  /* overflow: hidden; */
}

.image_gallery img {
  border-radius: 8px;
  height: 120px !important;
  width: 100%;
}

.image_gallery .image-overlay {
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

}

.image_gallery .image-container:hover .image-overlay {
  opacity: 1;
}

.image_gallery .icon {
  color: #fff;
  cursor: pointer;
  /* font-size: 1.5rem; */
}

.image_gallery .icon-close {
  background: #ff134e;
  cursor: pointer;
  /* font-size: 20px !important; */
  height: 14px;
  width: 14px;
  border-radius: 2px;
  padding: 3px;
  position: absolute;
  top: 1%;
  left: 99%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.image_gallery .image-name {
  color: white;
  font-size: 12px;
  position: absolute;
  bottom: 5%;
  line-height: 1;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 99;
}







.image_gallery2 .image-container {
  position: relative;

  /* overflow: hidden; */
}

.image_gallery2 img {
  border-radius: 8px;
  height: 140px !important;
  width: 90%;
  max-width: 300px;
  margin: 10px;
}

.image_gallery2 .image-overlay {
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 90%;
  max-width: 300px;
  margin: 10px;
  height: 140px;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

}

.image_gallery2 .image-container:hover .image-overlay {
  opacity: 1;
}

.image_gallery2 .icon {
  color: #fff;
  cursor: pointer;
  /* font-size: 1.5rem; */
}

.image_gallery2 .icon-close {
  background: #ff134e;
  cursor: pointer;
  /* font-size: 20px !important; */
  height: 14px;
  width: 14px;
  border-radius: 2px;
  padding: 3px;
  position: absolute;
  top: 1%;
  left: 99%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.image_gallery2 .image-name {
  color: white;
  font-size: 12px;
  position: absolute;
  bottom: 5%;
  line-height: 1;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 99;
}



.drawing_gallery .image-container {
  position: relative;

  /* overflow: hidden; */
}

.drawing_gallery img {
  border-radius: 8px;
  height: 120px !important;
  width: 100%;
}

.drawing_gallery .other_doc {
  border-radius: 8px;
  height: 120px !important;
  width: 100%;
  background: #ABBCCC;
}

.drawing_gallery .image-container .icon-close {
  background: #ff134e;
  cursor: pointer;
  /* font-size: 20px !important; */
  height: 14px;
  width: 14px;
  border-radius: 2px;
  padding: 3px;
  position: absolute;
  top: 1%;
  left: 99%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.drawing_gallery .image-name {
  font-size: 14px;
}

.slick-prev:before {
  color: #ff134e !important;
}

.slick-next:before {
  color: #ff134e !important;
}


.pc .accordion-button {
  padding: 12px 18px !important;
  font-size: clamp(15px, 18px, 18px) !important;
  background: #4e7dae !important;
  font-family: "inter-bold", sans-serif !important;
  color: white !important;
}


.pc .accordion-button:not(.collapsed)::after {
  background-image: url('./assets/images/acxordionArr.svg') !important;
}

.pc .accordion-button::after {
  background-image: url('./assets/images/accordionArr2.svg') !important;
}

.pc .accordion-body {
  background: white;
}

.searchWrapper {
  background: white;
}

.chip {
  background: #e7f4ff !important;
  color: #007bff !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  padding: 0px 8px 0px 8px !important;
}

.multiSelectContainer input {
  margin-top: 0px !important;
}

.custom-close {
  cursor: pointer;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #ff134e !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: #2a3643 !important;
}

.landing_page_title {
  margin-bottom: 0px !important;
  font-size: clamp(36px, 2vw, 34px);
  color: #2A3643;
}

.landing_page_tag_line {
  max-width: 900px;
  padding: 0px 40px;
  color: #344454;
  font-size: 30px
}

.landing_page_nav a {
  color: #8D8D8D !important;
}

.landing_page {
  overflow-x: hidden;
}

.login_page {
  overflow-x: hidden;
  /* padding: 20px; */
  height: 100vh;
}

.div_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text_gold {
  color: #EEAE2D;
}

.form_div {
  margin-top: 100px;
}

.login_page #login_img {
  height: 95vh;
}

.pink_rectangle {
  height: 6px;
  width: 130px;
  background: #FF134E;
}

.text_over_img {
  top: 10%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 400px;
  color: white;
  position: absolute;
  font-size: 1.4em;
}



@media (max-width: 768px) {

  /* Styles for smaller screens */
  .login_page {
    overflow-x: hidden;
    height: 100%;
  }

  .form_div {
    margin-top: 10px;
  }

  .login_page #login_img {
    height: 100%;
    margin-top: 15px;
  }

  .text_over_img {
    top: 10%;
    padding-left: 10px;
    padding-right: 10px;
    /* transform: translate(-50%, -50%); */
    max-width: 400px;
    color: white;
    position: absolute;
    font-size: 1.4em;
  }
}










/* circle pecentage */
.chart {
  width: 100px;
  height: 100px;
}

.base-circle {
  fill: none;
  stroke: #e0e0e0;
  stroke-width: 3;
  stroke-dasharray: 100;
  transition: stroke-dasharray 0.5s ease-in-out;
}

.circle-fill {
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: center;
  transition: stroke-dasharray 0.5s ease-in-out;
}

.percentage {
  text-align: center;
  margin-top: 8px;
  font-size: 18px;
  font-weight: bold;
}


.sm_circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.bg-pink {
  background: #FF134E;
}

.bg-yellow {
  background: #EEAE2D;
}

.nc_db_stat_label {
  color: #344454;
  font-size: 18px;
}

.nc_db_stat_value {
  font-size: clamp(16px, 40px, 40px);
}

.nc_db_per_content {
  color: #2A3643;
}












.pc table thead th {
  background: none;
  border: none;
  padding: 8px !important;
  border-bottom: 6px solid #f3f2f2;
}

.pc table .collapsible-row td {
  padding: 0px !important;
  border: 3px solid #f3f2f2;
}

.pc .collapsible-row .collapse-content {
  display: none;
  padding: 15px;
  background-color: #ABBCCC;
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-out;
  color: #344454;
}

.pc .collapsible-row.expanded .collapse-content {
  display: block;
  width: 100%;
  max-height: 500px;
}

.collapsible-row .label {
  font-size: 15px;
}




.steps span {
  opacity: 0.3;
}

.steps .active {
  opacity: 1 !important;
}


.prod_configurator .dropdown-menu {
  width: 250px;
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  /* Adjust the gap between pills as needed */
}

.custom-pill {
  background-color: #ABBCCC;
  color: #344454;
  border-radius: 2px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 15px;
  /* Adjust the margin between pills as needed */
  cursor: pointer;
  user-select: none;
}



.supplier_customsidebar .supplier_box {
  background: #d3dae0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 10px 7px 10px;
}

.supplier_customsidebar .supplier_box:hover {
  background: #ABBCCC;
}

.supplier_360_view .accordion {
  margin: 20px;

}

.supplier_360_view .accordion-item {
  /* border-top: none;
  border-right: none;
  border-left: none; */
  border-radius: 0;
  outline: none;
}

.supplier_360_view .accordion button:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.accordion-button:not(.collapsed) {
  background: #fff !important;
}

.supplier_360_view .accordion-header button {
  padding: 10px;
  font-size: 16px;
}

.supplier_360_view .div_one {
  background: white;
  /* border-right: 2px solid rgb(198, 198, 198); */
  font-size: 14px;
  padding: 20px 20px 20px 30px;
}

.supplier_360_view .label {
  color: #344454;
}

.prod_bom_accor .accordion-button {
  background-color: #8D8D8D !important;
  color: white !important;
}

.prod_bom_accor .accordion-body {
  background-color: #fdfbfb !important;
}

.accordion {
  --bs-accordion-btn-icon: url('./assets/images/acxordionArr.svg') !important;
  --bs-accordion-btn-active-icon: url('./assets/images/acxordionArr.svg') !important;

}


.PO_detail_modal table thead th {
  background: #2A3643 !important;
  color: #fff !important;
  font-family: "inter-bold", sans-serif !important;
  font-weight: "600" !important;
  text-align: center;
}

.no-badge-weight {
  --bs-badge-font-weight: 0 !important;
}

.PO_Details .accordion-button {
  background-color: #8D8D8D !important;
  color: white !important;
  padding: 10px !important;
}






.po_line {
  height: 2px;
  background: #ABBCCC;
  width: 100%;
}



.pi_data {
  color: #667080;
  font-size: 18px;
}






.inventory_customsidebar {
  height: 100%;
  width: 0;
  /* max-width: 320px !important; */
  overflow-x: auto;
  white-space: nowrap;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  transition: 0.5s ease-in-out;
}

.inventory_customsidebar #content {
  width: 320px;
  overflow-x: auto !important;
  max-height: 78vh;
  min-height: 78vh;
  margin-top: 60px;
}

.inventory_customsidebar .closebtn {
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 18px;
  font-size: 36px;
  cursor: pointer;
}




/* .inventory_customsidebar .supplier_box {
  background: #d3dae0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 10px 7px 10px;
}

.inventory_customsidebar .supplier_box:hover {
  background: #ABBCCC;
} */



.child {
  display: none;
  list-style-type: none;
  padding-left: 20px;
}

input[type="checkbox"]:checked+ul.child {
  display: block;
}










/*  */

:root {
  --line-color: #666;
  --line-width: 0.1em;
  --gutter: 2em;
}

.org_hierarychy .company_details {
  --bs-gutter-x: unset !important;
  --bs-gutter-y: unset !important;
  /* display: unset !important; */

}

.org_hierarychy .card-body {
  overflow-y: auto !important;
}

/* .org_hierarychy .card {
  max-width: 300px;
} */

.org_hierarychy .tree .card {
  max-width: 300px;
  min-width: 300px;
  background: red;
}

.org_hierarychy .tree {
  margin: 0 0 calc(var(--gutter) * 2);
  text-align: center;
}

.org_hierarychy .tree,
.org_hierarychy .tree ul,
.org_hierarychy .tree li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.org_hierarychy .tree,
.org_hierarychy .tree ul {
  display: table;
}

.org_hierarychy .tree ul {
  width: 100%;
}

.org_hierarychy .tree li {
  display: table-cell;
  padding: var(--gutter) 0;
  vertical-align: top;
}

.org_hierarychy .tree li:before {
  content: "";
  left: 0;
  outline: solid calc(var(--line-width) /2) var(--line-color);
  position: absolute;
  right: 0;
  top: 0;
}

.org_hierarychy .tree li:first-child:before {
  left: 50%;
}

.org_hierarychy .tree li:last-child:before {
  right: 50%;
}

.org_hierarychy .tree .card {
  border-radius: 0.2em;
  margin: 0 calc(var(--gutter) / 2) var(--gutter);
  min-height: 2.1em;
  position: relative;
  z-index: 1;
}

.org_hierarychy .tree [contenteditable] {
  cursor: text;
}

/* .org_hierarychy .tree .selected {
  border-color: #900;
  border-style: dashed;
  -webkit-box-shadow: 0 0 var(--gutter) var(--line-width) rgba(153, 0, 0, .3);
  -moz-box-shadow: 0 0 var(--gutter) var(--line-width) rgba(153, 0, 0, .3);
  box-shadow: 0 0 var(--gutter) var(--line-width) rgba(153, 0, 0, .3);
} */

.org_hierarychy .tree ul:before,
.org_hierarychy .tree .card:before {
  outline: solid calc(var(--line-width) / 2) var(--line-color);
  content: "";
  height: var(--gutter);
  left: 50%;
  position: absolute;
  top: calc(calc(-1 * var(--gutter)) - calc(var(--line-width) / 2));
}

.org_hierarychy .tree>li {
  margin-top: 0;
}

.org_hierarychy .tree>li:before,
.org_hierarychy .tree>li:after,
.org_hierarychy .tree>li>.card:before {
  outline: none;
}

.org_hierarychy .tree .card {
  -webkit-appearance: none;
  -moz-appearance: none;
  /* appearance: none; */
  background: #fff;
  border: solid var(--line-width) var(--line-color);
  cursor: pointer;
  font-size: 1em;
  /* line-height: 1.2em; */
  /* padding: 0.4em 1em; */
  position: relative;
}

/* .org_hierarychy .tree .card:focus,
.org_hierarychy .tree .card:hover {
  outline: 0.1em dotted var(--line-color);
  outline-offset: -0.5em;
} */

.org_hierarychy input[type="range"] {
  display: block;
  width: 100%;
}

.org_hierarychy input[type="color"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  display: block;
  height: 2em;
  padding: 0;
  vertical-align: middle;
  width: 100%;
}




.org_hierarychy .js-confirm .confirm,
.org_hierarychy .js-root .root {
  opacity: 1;
  transform: scale(1, 1);
}


::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #ebebeb !important;
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c0c0c0 !important;
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 168, 168) !important;
}




.createIcon_custom {
  background-color: #4E7DAE;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  /* border-radius: 50%; */
}

.editIcon_custom {
  background-color: #EEAE2D;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  /* border-radius: 50%; */
}

.deleteIcon_custom {
  background-color: #2A3643;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  /* border-radius: 50%; */
}


.org_hierarychy .make_active {
  background: #ABBCCC !important;
  color: #344454 !important;
}

.active_show{
color:#FF134E ;
}